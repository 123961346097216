var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrap"},[_c('div',{staticClass:"content"},_vm._l((_vm.teethOne),function(item,index){
var _obj;
return _c('div',{key:index,staticClass:"teeth",on:{"click":function($event){return _vm.teethClick(index, item, 'one')}}},[_c('span',[_vm._v(_vm._s(item.number))]),_c('el-popover',{ref:"onePopover",refInFor:true,attrs:{"placement":"bottom","disabled":_vm.readOnly || _vm.selectTeethFn(item.number, _vm.selectOne),"trigger":"click"},on:{"after-leave":function($event){return _vm.popoverHide(index, 'one', item)}}},[_c('div',{staticClass:"flex-y-c posr"},[_c('el-input',{staticStyle:{"width":"1.8rem"},attrs:{"value":item.width,"placeholder":_vm.$t('cases.createDiagnosis.jxl')},on:{"change":function($event){return _vm.disposeNumber({
                key: item,
                value: arguments[0],
                list: _vm.selectOne,
              })},"input":function($event){return _vm.disposeNumber({
                key: item,
                value: arguments[0],
                list: _vm.selectOne,
              })}},model:{value:(item.width),callback:function ($$v) {_vm.$set(item, "width", $$v)},expression:"item.width"}}),_c('span',{staticClass:"input_mm"},[_vm._v("mm")])],1),(index !== _vm.teethOne.length - 1)?_c('div',{class:( _obj = {
            active_img: item.width
          }, _obj[_vm.portImg] = item.width, _obj['hvr-bd curp'] =  !_vm.selectTeethFn(item.number, _vm.selectOne), _obj ),attrs:{"slot":"reference"},on:{"click":function($event){return _vm.resetNumber(item, 'teethOne', index)}},slot:"reference"}):_vm._e()])],1)}),0),_c('div',{staticClass:"content"},_vm._l((_vm.teethTow),function(item,index){
          var _obj;
return _c('div',{key:index,staticClass:"teeth",on:{"click":function($event){return _vm.teethClick(index, item, 'tow')}}},[_c('span',[_vm._v(_vm._s(item.number))]),_c('el-popover',{ref:"towPopover",refInFor:true,attrs:{"placement":"bottom","disabled":_vm.readOnly || _vm.selectTeethFn(item.number, _vm.selectTow),"trigger":"click"},on:{"after-leave":function($event){return _vm.popoverHide(index, 'tow', item)}}},[_c('div',{staticClass:"flex-y-c posr"},[_c('el-input',{staticStyle:{"width":"1.8rem"},attrs:{"value":item.width,"placeholder":_vm.$t('cases.createDiagnosis.jxl')},on:{"change":function($event){return _vm.disposeNumber({
                key: item,
                value: arguments[0],
                list: _vm.selectTow,
              })},"input":function($event){return _vm.disposeNumber({
                key: item,
                value: arguments[0],
                list: _vm.selectTow,
              })}},model:{value:(item.width),callback:function ($$v) {_vm.$set(item, "width", $$v)},expression:"item.width"}}),_c('span',{staticClass:"input_mm"},[_vm._v("mm")])],1),(index !== _vm.teethTow.length - 1)?_c('div',{class:( _obj = {
            active_img: item.width
          }, _obj[_vm.portImg] = item.width, _obj['hvr-bd curp'] =  !_vm.selectTeethFn(item.number, _vm.selectTow), _obj ),attrs:{"slot":"reference"},on:{"click":function($event){return _vm.resetNumber(item, 'teethTow', index)}},slot:"reference"}):_vm._e()])],1)}),0),(_vm.find(_vm.totalTeeth, function (item) { return item.width; }))?_c('div',{staticClass:"detail"},[_vm._l((_vm.totalTeeth),function(item,index){return [(item.width)?_c('p',{key:index,staticClass:"fz14 mb4 main_theme_color_333"},[_vm._v(" "+_vm._s(item.number)+", "+_vm._s(item.nextNumber)+" "+_vm._s(_vm.$t("cases.createDiagnosis.yljgw"))+" "),_c('span',[_vm._v(_vm._s(item.width)+" mm")])]):_vm._e()]})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <ul
    :class="
      ['A', 'E', 'B', 'G', 'H', 'I'].includes($route.query.productType)
        ? 'w65'
        : 'w46'
    "
    class="teeth_ul"
    @click.stop="selectedTeeth"
  >
    <li
      class="teeth_single"
      :class="{ children_height: patientType === 'children' }"
    >
      <div class="single_01" v-if="patientType !== 'gap'">
        <p
          v-for="item in [51, 52, 53, 54, 55]"
          :class="{
            is_active: selectList.indexOf(item) !== -1,
          }"
          :key="`teeth_${item}`"
        >
          <span class="single_mdl" :data-key="item"></span>
          <span
            class="single_02_txt"
            :class="{
              txt_active:
                (noMoveList.indexOf(item) !== -1 &&
                  (teethType === 2 || teethType === 4)) ||
                (noDesignList.indexOf(item) !== -1 && teethType === 3) ||
                (removeList.indexOf(item) !== -1 &&
                  [4, 6].includes(teethType)) ||
                (nearList.indexOf(item) !== -1 && teethType === 6),
            }"
            >{{ item }}</span
          >
          <span
            class="tag_sp"
            :data-key="item"
            :class="queryPortClass({ item })"
          ></span>
        </p>
      </div>
      <div
        v-if="['youth', 'adult', 'gap'].includes(patientType)"
        class="single_02"
      >
        <p
          v-for="item in [11, 12, 13, 14, 15, 16, 17, 18]"
          :key="`teeth_${item}`"
          :data-key="item"
          :class="{
            is_active: selectList.indexOf(item) !== -1,
          }"
        >
          <span
            :data-key="item"
            class="single_02_txt"
            :class="{
              txt_active:
                (noMoveList.indexOf(item) !== -1 &&
                  (teethType === 2 || teethType === 4)) ||
                (noDesignList.indexOf(item) !== -1 && teethType === 3) ||
                (removeList.indexOf(item) !== -1 &&
                  [4, 6].includes(teethType)) ||
                (nearList.indexOf(item) !== -1 && teethType === 6) ||
                deficiencyList.indexOf(item) !== -1,
            }"
            >{{ item }}</span
          >
          <span
            class="tag_sp"
            :data-key="item"
            :class="queryPortClass({ item })"
          ></span>
        </p>
      </div>
    </li>
    <li
      class="teeth_single"
      :class="{ children_height: patientType === 'children' }"
    >
      <div class="single_01" v-if="patientType !== 'gap'">
        <p
          v-for="item in [61, 62, 63, 64, 65]"
          :key="`teeth_${item}`"
          :data-key="item"
          :class="{
            is_active: selectList.indexOf(item) !== -1,
          }"
        >
          <span class="single_mdl" :data-key="item"></span>
          <span
            class="single_02_txt"
            :class="{
              txt_active:
                (noMoveList.indexOf(item) !== -1 &&
                  (teethType === 2 || teethType === 4)) ||
                (noDesignList.indexOf(item) !== -1 && teethType === 3) ||
                (removeList.indexOf(item) !== -1 &&
                  [4, 6].includes(teethType)) ||
                (nearList.indexOf(item) !== -1 && teethType === 6),
            }"
            >{{ item }}</span
          >
          <span class="tag_sp" :class="queryPortClass({ item })"></span>
        </p>
      </div>
      <div
        v-if="['youth', 'adult', 'gap'].includes(patientType)"
        class="single_02"
      >
        <p
          v-for="item in [21, 22, 23, 24, 25, 26, 27, 28]"
          :key="`teeth_${item}`"
          :data-key="item"
          :class="{
            is_active: selectList.indexOf(item) !== -1,
          }"
        >
          <span
            class="single_02_txt"
            :data-key="item"
            :class="{
              txt_active:
                (noMoveList.indexOf(item) !== -1 &&
                  (teethType === 2 || teethType === 4)) ||
                (noDesignList.indexOf(item) !== -1 && teethType === 3) ||
                (removeList.indexOf(item) !== -1 &&
                  [4, 6].includes(teethType)) ||
                (nearList.indexOf(item) !== -1 && teethType === 6) ||
                deficiencyList.indexOf(item) !== -1,
            }"
            >{{ item }}</span
          >
          <span
            class="tag_sp"
            :data-key="item"
            :class="queryPortClass({ item })"
          ></span>
        </p>
      </div>
    </li>
    <li
      class="teeth_single"
      :class="{
        children_height: patientType === 'children',
        children_padding_top: patientType === 'children',
      }"
    >
      <div
        v-if="['youth', 'adult', 'gap'].includes(patientType)"
        class="single_02"
      >
        <p
          v-for="item in [41, 42, 43, 44, 45, 46, 47, 48]"
          :key="`teeth_${item}`"
          :data-key="item"
          :class="{
            is_active: selectList.indexOf(item) !== -1,
          }"
        >
          <span
            :data-key="item"
            :class="{
              txt_active:
                (noMoveList.indexOf(item) !== -1 &&
                  (teethType === 2 || teethType === 4)) ||
                (noDesignList.indexOf(item) !== -1 && teethType === 3) ||
                (removeList.indexOf(item) !== -1 &&
                  [4, 6].includes(teethType)) ||
                (nearList.indexOf(item) !== -1 && teethType === 6) ||
                deficiencyList.indexOf(item) !== -1,
            }"
            class="single_02_txt"
            >{{ item }}</span
          >
          <span
            :data-key="item"
            class="tag_sp"
            :class="queryPortClass({ item })"
          ></span>
        </p>
      </div>
      <div
        :class="{ single_01_mt: patientType === 'children' }"
        class="single_01"
        v-if="patientType !== 'gap'"
      >
        <p
          v-for="item in [81, 82, 83, 84, 85]"
          :data-key="item"
          :key="item"
          :class="{
            is_active: selectList.indexOf(item) !== -1,
          }"
        >
          <span :data-key="item" class="single_mdl"></span>
          <span
            class="single_02_txt"
            :data-key="item"
            :class="{
              txt_active:
                (noMoveList.indexOf(item) !== -1 &&
                  (teethType === 2 || teethType === 4)) ||
                (noDesignList.indexOf(item) !== -1 && teethType === 3) ||
                (removeList.indexOf(item) !== -1 &&
                  [4, 6].includes(teethType)) ||
                (nearList.indexOf(item) !== -1 && teethType === 6),
            }"
            >{{ item }}</span
          >
          <span
            class="tag_sp"
            :data-key="item"
            :class="queryPortClass({ item })"
          ></span>
        </p>
      </div>
    </li>
    <li
      class="teeth_single"
      :class="{
        children_height: patientType === 'children',
        children_padding_top: patientType === 'children',
      }"
    >
      <div
        v-if="['youth', 'adult', 'gap'].includes(patientType)"
        class="single_02"
      >
        <p
          v-for="item in [31, 32, 33, 34, 35, 36, 37, 38]"
          :key="`teeth_${item}`"
          :data-key="item"
          :class="{
            is_active: selectList.indexOf(item) !== -1,
          }"
        >
          <span
            class="single_02_txt"
            :data-key="item"
            :class="{
              txt_active:
                (noMoveList.indexOf(item) !== -1 &&
                  (teethType === 2 || teethType === 4)) ||
                (noDesignList.indexOf(item) !== -1 && teethType === 3) ||
                (removeList.indexOf(item) !== -1 &&
                  [4, 6].includes(teethType)) ||
                (nearList.indexOf(item) !== -1 && teethType === 6) ||
                deficiencyList.indexOf(item) !== -1,
            }"
            >{{ item }}</span
          >
          <span
            class="tag_sp"
            :data-key="item"
            :class="queryPortClass({ item })"
          ></span>
        </p>
      </div>
      <div
        :class="{ single_01_mt: patientType === 'children' }"
        class="single_01"
        v-if="patientType !== 'gap'"
      >
        <p
          v-for="item in [71, 72, 73, 74, 75]"
          :key="`teeth_${item}`"
          :data-key="item"
          :class="{
            is_active: selectList.indexOf(item) !== -1,
          }"
        >
          <span :data-key="item" class="single_mdl"></span>
          <span
            class="single_02_txt"
            :data-key="item"
            :class="{
              txt_active:
                (noMoveList.indexOf(item) !== -1 &&
                  (teethType === 2 || teethType === 4)) ||
                (noDesignList.indexOf(item) !== -1 && teethType === 3) ||
                (removeList.indexOf(item) !== -1 &&
                  [4, 6].includes(teethType)) ||
                (nearList.indexOf(item) !== -1 && teethType === 6),
            }"
            >{{ item }}</span
          >
          <span
            class="tag_sp"
            :data-key="item"
            :class="queryPortClass({ item })"
          ></span>
        </p>
      </div>
    </li>
  </ul>
</template>

<script>
import { filter, map, isArray, each, get, pickBy, extend } from "lodash";

export default {
  data() {
    return {
      selectObjList: [],
    };
  },
  computed: {
    selectList() {
      return map(this.selectObjList, (item) => item && item.number);
    },
    noMoveList() {
      return map(
        filter(this.selectObjList, (item) => item.noMove === "noMove"),
        (it) => it.number
      );
    },
    noDesignList() {
      return map(
        filter(this.selectObjList, (item) => item.noDesign === "noDesign"),
        (it) => it.number
      );
    },
    removeList() {
      return map(
        filter(this.selectObjList, (item) => item.remove === "remove"),
        (it) => it.number
      );
    },
    deficiencyList() {
      return map(
        filter(this.selectObjList, (item) => item.deficiency === "deficiency"),
        (it) => it.number
      );
    },
    nearList() {
      return map(
        filter(this.selectObjList, (item) => item.near === "near"),
        (it) => it.number
      );
    },
    queryPort() {
      return this.$route.query.port;
    },
  },
  props: {
    //adult  youth  children
    patientType: {
      type: String,
      default: "youth",
    },
    teethType: {
      //牙齿种类 1: 选择牙齿 2不可移动 3不可设计 4 拔除
      type: Number | String,
      default: "",
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    commonTeeth: {
      type: Array,
      required: true,
    },
  },
  methods: {
    queryPortClass({ item }) {
      let teethType = this.teethType;
      if (
        this.noMoveList.indexOf(item) !== -1 &&
        (teethType === 2 || teethType === 4)
      ) {
        return [
          {
            irdt: "purple_teeth_no_move",
            admin: "blue_teeth_no_move",
            clinic: "blue_teeth_no_move",
          }[this.queryPort] || "teeth_no_move",
        ];
      } else if (this.noDesignList.indexOf(item) !== -1 && teethType === 3) {
        return [
          {
            irdt: "purple_teeth_no_design",
            admin: "blue_teeth_no_design",
            clinic: "blue_teeth_no_design",
          }[this.queryPort] || "teeth_no_design",
        ];
      } else if (
        this.removeList.indexOf(item) !== -1 &&
        [4, 6].includes(teethType)
      ) {
        return [
          {
            irdt: "purple_teeth_extraction",
            admin: "blue_teeth_extraction",
            clinic: "blue_teeth_extraction",
          }[this.queryPort] || "teeth_extraction",
        ];
      } else if (this.nearList.indexOf(item) !== -1 && teethType === 6) {
        return [
          {
            irdt: "purple_teeth_near",
            admin: "blue_teeth_near",
            clinic: "blue_teeth_near",
          }[this.queryPort] || "teeth_near",
        ];
      } else if (this.deficiencyList.indexOf(item) !== -1) {
        return "teeth_deficiency";
      }
    },
    selectedTeeth(ev) {
      if (this.readOnly) return;
      const target = ev.target;
      if (
        target.tagName.toLocaleLowerCase() === "p" ||
        target.tagName.toLocaleLowerCase() === "span"
      ) {
        const key = +target.getAttribute("data-key");
        let selectList = this.selectList;
        if (this.teethType === 6) {
          if (this.removeList.includes(key)) return;
        }
        if (this.teethType === 1) {
          let uploadClickTeeth = function(that) {
            if (selectList.indexOf(key) !== -1) {
              that.selectObjList.splice(selectList.indexOf(key), 1);
              return that.updateTeeth();
            }
            that.selectObjList.push({
              number: key,
            });
            return that.updateTeeth();
          };
          if (["adult", "gap"].includes(this.patientType)) {
            let list = [
              55,
              54,
              53,
              52,
              51,
              61,
              62,
              63,
              64,
              65,
              85,
              84,
              83,
              82,
              81,
              71,
              72,
              73,
              74,
              75,
            ];
            if (list.includes(key)) {
              return uploadClickTeeth(this);
            } else {
              return this.otherTeethClick(key);
            }
          } else {
            return uploadClickTeeth(this);
          }
        }
        if (this.selectList.indexOf(key) !== -1) {
          if (this.deficiencyList.indexOf(key) !== -1) return;

          if ([4].includes(this.teethType)) {
            let isClick;
            each(this.selectObjList, (item) => {
              if (item.number === key) {
                isClick = item.noMove;
              }
            });
            if (isClick) return;
            this.otherTeethClick(key);
          } else {
            this.otherTeethClick(key);
          }
        }
      }
    },
    otherTeethClick(key) {
      if (this.readOnly) return;
      let list =
        ["adult", "gap"].includes(this.patientType) && this.teethType === 1
          ? this.deficiencyList
          : {
              2: this.noMoveList,
              3: this.noDesignList,
              4: this.removeList,
              6: this.nearList,
            }[this.teethType];
      let customType =
        ["adult", "gap"].includes(this.patientType) && this.teethType === 1
          ? "deficiency"
          : {
              2: "noMove",
              3: "noDesign",
              4: "remove",
              6: "near",
            }[this.teethType];

      if (isArray(list) && list.indexOf(key) !== -1) {
        this.selectObjList = map(this.selectObjList, (item) => {
          if (item.number === key) {
            return {
              ...item,
              [customType]: "",
            };
          }
          return item;
        });
        return this.updateTeeth();
      }
      this.selectObjList = map(this.selectObjList, (item) => {
        if (item && item.number === key) {
          if (customType === "deficiency") {
            return {
              number: item.number,
              type: item.type,
              deficiency: "deficiency",
            };
          }
          return pickBy({
            ...item,
            [customType]: customType,
            [this.teethType === 2 ? "remove" : ""]: "",
          });
        }
        return item;
      });
      this.updateTeeth();
    },
    updateTeeth() {
      if (this.readOnly) return;
      this.$emit("updateTeeth", this.selectObjList);
    },
    clearTeethType(type) {
      // 2不可移动 3不可设计 4 拔除
      let teethType = {
        2: "noMove",
        3: "noDesign",
        4: "remove",
        6: "near",
      }[type];
      this.selectObjList = map(this.selectObjList, (item) => {
        return pickBy(extend({}, item, { [teethType]: "" }));
      });
      this.updateTeeth();
    },
  },
  watch: {
    commonTeeth(val) {
      this.selectObjList = val;
    },
  },
  created() {
    if (isArray(this.commonTeeth)) {
      this.selectObjList = this.commonTeeth;
    }
  },
};
</script>

<style scoped lang="scss">
.teeth_ul {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.18rem;
  color: #999999;
  margin: 0 auto;

  .teeth_single {
    width: 50%;
    box-sizing: border-box;

    &:first-child {
      border-right: 0.01rem #c1c1c1 dashed;
      border-bottom: 0.01rem #c1c1c1 dashed;
      padding-bottom: 0.11rem;
      padding-right: 0.09rem;

      .single_01 {
        margin-bottom: 0.26rem;
        flex-direction: row-reverse;
      }

      .single_02 {
        flex-direction: row-reverse;
      }

      p {
        margin-left: 0.18rem;
      }
    }

    &:last-child {
      border-left: 0.01rem #c1c1c1 dashed;
      border-top: 0.01rem #c1c1c1 dashed;
      padding-top: 0.14rem;
      padding-left: 0.09rem;
      transform: translate(-0.01rem, -0.01rem);

      .single_01 {
        margin-top: 0.26rem;
      }

      p {
        margin-right: 0.18rem;
      }
    }

    &:nth-of-type(2) {
      padding-bottom: 0.11rem;
      padding-left: 0.09rem;

      .single_01 {
        margin-bottom: 0.26rem;
      }

      p {
        margin-right: 0.18rem;
      }
    }

    &:nth-of-type(3) {
      padding-right: 0.09rem;
      padding-top: 0.14rem;

      .single_01 {
        margin-top: 0.26rem;
        flex-direction: row-reverse;
      }

      .single_02 {
        flex-direction: row-reverse;
      }

      p {
        margin-left: 0.18rem;
      }
    }

    .single_01 {
      display: flex;
      align-items: center;

      p {
        border: 0.01rem solid #c8c8c8;
        box-sizing: border-box;
        overflow: hidden;
        cursor: pointer;
      }

      .single_ac_p {
        border: 0.01rem solid $main_theme_color;
        color: $main_theme_color;
      }
    }

    .single_02 {
      display: flex;
      align-items: center;

      p {
        border: 0.01rem solid #c8c8c8;
        box-sizing: border-box;
        cursor: pointer;
        overflow: hidden;
      }
    }

    p {
      min-width: 0.32rem;
      min-height: 0.32rem;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.06rem;

      .single_02_txt {
        position: absolute;
        right: 50%;
        top: 50%;
        transform: translate(50%, -50%);
        font-size: 0.2rem;
      }

      .txt_active {
        color: $main_theme_color;
        top: 30%;
        right: 30%;
        font-size: 0.15rem;
        z-index: 2;
      }

      .tag_sp {
        position: absolute;
        left: -1px;
        bottom: -1px;
        right: -1px;
        top: -1px;
        display: inline-block;
        background-size: (100% 100%);
      }
    }

    .single_mdl {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 9;
      cursor: pointer;
    }
  }

  .teeth_missing {
    background-image: url("/static/images/createCases/teeth_defect.png");
  }

  .teeth_no_move {
    background-image: url("/static/images/createCases/teeth_fixed.png");
  }

  .teeth_deficiency {
    background-image: url("/static/images/createCases/teeth_defect.png");
  }

  .blue_teeth_no_move {
    background-image: url("./img/blue_suo.png");
  }

  .purple_teeth_no_move {
    background-image: url("./img/purple_suo.png");
  }

  .teeth_no_design {
    background-image: url("/static/images/createCases/teeth_notdesigned.png");
  }

  .blue_teeth_no_design {
    background-image: url("./img/blue_sj.png");
  }

  .purple_teeth_no_design {
    background-image: url("./img/purple_js.png");
  }

  .teeth_extraction {
    background-image: url("/static/images/createCases/teeth_extraction.png");
  }

  .blue_teeth_extraction {
    background-image: url("./img/blue_by.png");
  }

  .purple_teeth_extraction {
    background-image: url("./img/purple_by.png");
  }

  .teeth_near {
    background-image: url("/static/images/createCases/teeth_near.png");
  }

  .t__s_tag_fj {
    background-image: url("/static/images/teeth_fj_tag.png");
    right: 45% !important;
    top: 45% !important;
  }
}

.w65 {
  width: 7.8rem !important;
}

.w46 {
  width: 4.8rem !important;
}

.children_height {
  height: 0.5rem !important;
}

.children_padding_top {
  padding-top: 0 !important;
}

.single_01_mt {
  margin-top: 0.18rem !important;
}

.is_active {
  color: $main_theme_color !important;
  border: 0.01rem solid $main_theme_color !important;
}
</style>

<template>
  <li class="preview_single">
    <div class="preview_s_left">
      <h5 class="s_l_h5">
        <span :class="'yellow_fz'" class="s_l_icon"></span>
        {{ $t("casesDetail.publicCases.casesUser.jbxx") }}
      </h5>
      <div class="s_l_box">
        <p class="s_l_box_p">
          <span class="mr40 user_name txt-ellipsis">
            {{ $t("cases.createUser.hzmc") }}：{{ userInfo.realName }}
          </span>
          <span class="mr40">
            {{ $t("cases.createUser.xb") }}：{{
              userInfo.sex
                  ? userInfo.sex === "1"
                      ? $t("casesDetail.publicCases.casesUser.n")
                      : $t("casesDetail.publicCases.casesUser.v")
                  : "-"
            }}</span
          >
          <span
          >{{ $t("cases.createUser.csrq") }}：{{ userInfo.birthdayStr }}</span
          >
        </p>
        <p
            class="s_l_box_p"
            style="
            width: 400px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
          "
        >
          {{ $t("cases.cases.zs") }}：{{
            get(caseInfo, "clinicName") || get(caseInfo, "caseInfo.clinicName")
          }}
        </p>
      </div>
    </div>
    <div
        :style="
        language === 'en_US'
          ? 'width: 4rem; margin-left: 0.8rem'
          : 'width: 3.2rem'
      "
        class="preview_s_right"
    >
      <div>
        <span class="preview_s_r_strong"
        >{{ $t("casesDetail.casesChildren.reviewPlan.blh") }}：</span
        >
        <input
            id="casesNumber"
            :value="
            get(caseInfo, 'caseNumber') || get(caseInfo, 'caseInfo.caseNumber')
          "
            class="preview_s_r_strong"
            readonly
        />
      </div>
      <span
          ref="copyCases"
          :class="'yellow_fz'"
          class="preview_s_r_icon"
          data-clipboard-action="copy"
          data-clipboard-target="#casesNumber"
          @click="copyCasesNumber"
      ></span>
    </div>
  </li>
</template>

<script>
import clipboard from "clipboard";
import {notifyMsg} from "common/js/util";
import {get, map} from "lodash";
import {mapState} from "vuex";

export default {
  data() {
    return {
      copyCases: null,
    };
  },
  mounted() {
    if (this.$refs.copyCases) {
      this.copyCases = new clipboard(this.$refs.copyCases);
    }
  },
  computed: {
    ...mapState({
      language: (state) => state.language,
    }),
    queryPort() {
      return this.$route.query.port;
    },
  },
  methods: {
    get,
    map,
    copyCasesNumber() {
      notifyMsg(
          this,
          "success",
          this.$t("casesDetail.publicCases.casesUser.fzcg")
      );
    },
  },
  props: {
    userInfo: {
      type: Object,
      default: () => ({}),
    },
    caseInfo: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.preview_single {
  display: flex;
  margin-bottom: 0.43rem;
  position: relative;
}

.preview_s_left {
  .s_l_h5 {
    display: flex;
    align-items: center;
    margin-bottom: 0.2rem;
    font-size: 16px;
    color: $main_theme_color_333;

    .s_l_icon {
      //margin-right: 0.1rem;
      display: inline-block;
      width: 0.24rem;
      height: 0.24rem;
      background-size: 100% 100%;
    }

    .yellow_user {
      background-image: url("../../../common/imgs/case/case_user_message.png");
    }

    .blue_user {
      background-image: url("../../../common/imgs/user_data/blue_user.png");
    }

    .purple_user {
      background-image: url("../../../common/imgs/user_data/purple_user.png");
    }
  }

  .s_l_box {
    color: $main_theme_color_333;
    font-size: 14px;
    padding-left: 0.24rem;

    .s_l_box_p {
      display: flex;
      align-items: center;
      margin-bottom: 0.1rem;
      height: 20px;

      .user_name {
        display: inline-block;
        width: 1.7rem;
        line-height: 0.2rem;
      }
    }

    .s_l_con {
      display: flex;
      margin-bottom: 0.31rem;
      position: relative;

      .s_l_con_left {
        margin-right: 0.1rem;
        white-space: nowrap;
      }

      .s_l_con_right {
        flex: 1;

        .p {
          margin-bottom: 0.21rem;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .note_box {
          height: 0.6rem;
        }

        .s_l_con_note {
          position: absolute;
          left: 0;
          width: 100%;
          padding: 0.2rem 0.1rem;
          background-color: #f3f3f3;
        }

        .r_strong {
          font-weight: 600;
          margin-left: 0.1rem;
          color: #111;
        }
      }

      .s_l_con_teeth {
        padding-top: 0.4rem;
        padding-bottom: 0.2rem;
      }
    }
  }

  .pre_list {
    margin-bottom: 0.1rem;
    padding-left: 0.24rem;

    .s_l_txt {
      color: #666;
      margin-bottom: 0.12rem;
    }

    li {
      width: 3.14rem;
      height: 2rem;
      border-radius: 0.06rem;
      background-size: 100% 100%;
      margin-bottom: 0.2rem;

      &:last-child {
        margin-right: 0;
      }
    }

    .pic_list_space {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }

    .pic_list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      li {
        margin-right: 0.3rem;
      }
    }
  }
}

.preview_s_right {
  height: 0.52rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f3f3f3;
  border-radius: 0.06rem;
  padding: 16px 20px;
  position: absolute;
  top: 40px;
  left: 493px;

  .preview_s_r_strong {
    font-size: 0.14rem;
    color: $main_theme_color_333;
  }

  .preview_s_r_icon {
    display: inline-block;
    width: 0.24rem;
    height: 0.25rem;
    background-size: 100% 100%;
    cursor: pointer;
  }

  .yellow_fz {
    background-image: url("../../../common/imgs/3d/case_user_plus.png");
  }

  .blue_fz {
    background-image: url("../../../common/imgs/user_data/blue_fz.png");
  }

  .purple_fz {
    background-image: url("../../../common/imgs/user_data/purple_fz.png");
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"teeth_ul",class:['A', 'E', 'B', 'G', 'H', 'I'].includes(_vm.$route.query.productType)
      ? 'w65'
      : 'w46',on:{"click":function($event){$event.stopPropagation();return _vm.selectedTeeth($event)}}},[_c('li',{staticClass:"teeth_single",class:{ children_height: _vm.patientType === 'children' }},[(_vm.patientType !== 'gap')?_c('div',{staticClass:"single_01"},_vm._l(([51, 52, 53, 54, 55]),function(item){return _c('p',{key:("teeth_" + item),class:{
          is_active: _vm.selectList.indexOf(item) !== -1,
        }},[_c('span',{staticClass:"single_mdl",attrs:{"data-key":item}}),_c('span',{staticClass:"single_02_txt",class:{
            txt_active:
              (_vm.noMoveList.indexOf(item) !== -1 &&
                (_vm.teethType === 2 || _vm.teethType === 4)) ||
              (_vm.noDesignList.indexOf(item) !== -1 && _vm.teethType === 3) ||
              (_vm.removeList.indexOf(item) !== -1 &&
                [4, 6].includes(_vm.teethType)) ||
              (_vm.nearList.indexOf(item) !== -1 && _vm.teethType === 6),
          }},[_vm._v(_vm._s(item))]),_c('span',{staticClass:"tag_sp",class:_vm.queryPortClass({ item: item }),attrs:{"data-key":item}})])}),0):_vm._e(),(['youth', 'adult', 'gap'].includes(_vm.patientType))?_c('div',{staticClass:"single_02"},_vm._l(([11, 12, 13, 14, 15, 16, 17, 18]),function(item){return _c('p',{key:("teeth_" + item),class:{
          is_active: _vm.selectList.indexOf(item) !== -1,
        },attrs:{"data-key":item}},[_c('span',{staticClass:"single_02_txt",class:{
            txt_active:
              (_vm.noMoveList.indexOf(item) !== -1 &&
                (_vm.teethType === 2 || _vm.teethType === 4)) ||
              (_vm.noDesignList.indexOf(item) !== -1 && _vm.teethType === 3) ||
              (_vm.removeList.indexOf(item) !== -1 &&
                [4, 6].includes(_vm.teethType)) ||
              (_vm.nearList.indexOf(item) !== -1 && _vm.teethType === 6) ||
              _vm.deficiencyList.indexOf(item) !== -1,
          },attrs:{"data-key":item}},[_vm._v(_vm._s(item))]),_c('span',{staticClass:"tag_sp",class:_vm.queryPortClass({ item: item }),attrs:{"data-key":item}})])}),0):_vm._e()]),_c('li',{staticClass:"teeth_single",class:{ children_height: _vm.patientType === 'children' }},[(_vm.patientType !== 'gap')?_c('div',{staticClass:"single_01"},_vm._l(([61, 62, 63, 64, 65]),function(item){return _c('p',{key:("teeth_" + item),class:{
          is_active: _vm.selectList.indexOf(item) !== -1,
        },attrs:{"data-key":item}},[_c('span',{staticClass:"single_mdl",attrs:{"data-key":item}}),_c('span',{staticClass:"single_02_txt",class:{
            txt_active:
              (_vm.noMoveList.indexOf(item) !== -1 &&
                (_vm.teethType === 2 || _vm.teethType === 4)) ||
              (_vm.noDesignList.indexOf(item) !== -1 && _vm.teethType === 3) ||
              (_vm.removeList.indexOf(item) !== -1 &&
                [4, 6].includes(_vm.teethType)) ||
              (_vm.nearList.indexOf(item) !== -1 && _vm.teethType === 6),
          }},[_vm._v(_vm._s(item))]),_c('span',{staticClass:"tag_sp",class:_vm.queryPortClass({ item: item })})])}),0):_vm._e(),(['youth', 'adult', 'gap'].includes(_vm.patientType))?_c('div',{staticClass:"single_02"},_vm._l(([21, 22, 23, 24, 25, 26, 27, 28]),function(item){return _c('p',{key:("teeth_" + item),class:{
          is_active: _vm.selectList.indexOf(item) !== -1,
        },attrs:{"data-key":item}},[_c('span',{staticClass:"single_02_txt",class:{
            txt_active:
              (_vm.noMoveList.indexOf(item) !== -1 &&
                (_vm.teethType === 2 || _vm.teethType === 4)) ||
              (_vm.noDesignList.indexOf(item) !== -1 && _vm.teethType === 3) ||
              (_vm.removeList.indexOf(item) !== -1 &&
                [4, 6].includes(_vm.teethType)) ||
              (_vm.nearList.indexOf(item) !== -1 && _vm.teethType === 6) ||
              _vm.deficiencyList.indexOf(item) !== -1,
          },attrs:{"data-key":item}},[_vm._v(_vm._s(item))]),_c('span',{staticClass:"tag_sp",class:_vm.queryPortClass({ item: item }),attrs:{"data-key":item}})])}),0):_vm._e()]),_c('li',{staticClass:"teeth_single",class:{
      children_height: _vm.patientType === 'children',
      children_padding_top: _vm.patientType === 'children',
    }},[(['youth', 'adult', 'gap'].includes(_vm.patientType))?_c('div',{staticClass:"single_02"},_vm._l(([41, 42, 43, 44, 45, 46, 47, 48]),function(item){return _c('p',{key:("teeth_" + item),class:{
          is_active: _vm.selectList.indexOf(item) !== -1,
        },attrs:{"data-key":item}},[_c('span',{staticClass:"single_02_txt",class:{
            txt_active:
              (_vm.noMoveList.indexOf(item) !== -1 &&
                (_vm.teethType === 2 || _vm.teethType === 4)) ||
              (_vm.noDesignList.indexOf(item) !== -1 && _vm.teethType === 3) ||
              (_vm.removeList.indexOf(item) !== -1 &&
                [4, 6].includes(_vm.teethType)) ||
              (_vm.nearList.indexOf(item) !== -1 && _vm.teethType === 6) ||
              _vm.deficiencyList.indexOf(item) !== -1,
          },attrs:{"data-key":item}},[_vm._v(_vm._s(item))]),_c('span',{staticClass:"tag_sp",class:_vm.queryPortClass({ item: item }),attrs:{"data-key":item}})])}),0):_vm._e(),(_vm.patientType !== 'gap')?_c('div',{staticClass:"single_01",class:{ single_01_mt: _vm.patientType === 'children' }},_vm._l(([81, 82, 83, 84, 85]),function(item){return _c('p',{key:item,class:{
          is_active: _vm.selectList.indexOf(item) !== -1,
        },attrs:{"data-key":item}},[_c('span',{staticClass:"single_mdl",attrs:{"data-key":item}}),_c('span',{staticClass:"single_02_txt",class:{
            txt_active:
              (_vm.noMoveList.indexOf(item) !== -1 &&
                (_vm.teethType === 2 || _vm.teethType === 4)) ||
              (_vm.noDesignList.indexOf(item) !== -1 && _vm.teethType === 3) ||
              (_vm.removeList.indexOf(item) !== -1 &&
                [4, 6].includes(_vm.teethType)) ||
              (_vm.nearList.indexOf(item) !== -1 && _vm.teethType === 6),
          },attrs:{"data-key":item}},[_vm._v(_vm._s(item))]),_c('span',{staticClass:"tag_sp",class:_vm.queryPortClass({ item: item }),attrs:{"data-key":item}})])}),0):_vm._e()]),_c('li',{staticClass:"teeth_single",class:{
      children_height: _vm.patientType === 'children',
      children_padding_top: _vm.patientType === 'children',
    }},[(['youth', 'adult', 'gap'].includes(_vm.patientType))?_c('div',{staticClass:"single_02"},_vm._l(([31, 32, 33, 34, 35, 36, 37, 38]),function(item){return _c('p',{key:("teeth_" + item),class:{
          is_active: _vm.selectList.indexOf(item) !== -1,
        },attrs:{"data-key":item}},[_c('span',{staticClass:"single_02_txt",class:{
            txt_active:
              (_vm.noMoveList.indexOf(item) !== -1 &&
                (_vm.teethType === 2 || _vm.teethType === 4)) ||
              (_vm.noDesignList.indexOf(item) !== -1 && _vm.teethType === 3) ||
              (_vm.removeList.indexOf(item) !== -1 &&
                [4, 6].includes(_vm.teethType)) ||
              (_vm.nearList.indexOf(item) !== -1 && _vm.teethType === 6) ||
              _vm.deficiencyList.indexOf(item) !== -1,
          },attrs:{"data-key":item}},[_vm._v(_vm._s(item))]),_c('span',{staticClass:"tag_sp",class:_vm.queryPortClass({ item: item }),attrs:{"data-key":item}})])}),0):_vm._e(),(_vm.patientType !== 'gap')?_c('div',{staticClass:"single_01",class:{ single_01_mt: _vm.patientType === 'children' }},_vm._l(([71, 72, 73, 74, 75]),function(item){return _c('p',{key:("teeth_" + item),class:{
          is_active: _vm.selectList.indexOf(item) !== -1,
        },attrs:{"data-key":item}},[_c('span',{staticClass:"single_mdl",attrs:{"data-key":item}}),_c('span',{staticClass:"single_02_txt",class:{
            txt_active:
              (_vm.noMoveList.indexOf(item) !== -1 &&
                (_vm.teethType === 2 || _vm.teethType === 4)) ||
              (_vm.noDesignList.indexOf(item) !== -1 && _vm.teethType === 3) ||
              (_vm.removeList.indexOf(item) !== -1 &&
                [4, 6].includes(_vm.teethType)) ||
              (_vm.nearList.indexOf(item) !== -1 && _vm.teethType === 6),
          },attrs:{"data-key":item}},[_vm._v(_vm._s(item))]),_c('span',{staticClass:"tag_sp",class:_vm.queryPortClass({ item: item }),attrs:{"data-key":item}})])}),0):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <li class="preview_single">
    <div class="preview_s_left">
      <h5 class="s_l_h5">
        <span :class="{
          'clinic': 'blue_icon',
          'admin': 'blue_icon',
          'irdt': 'purple_icon',
        }[queryPort] || 'yellow_icon'" class="s_l_icon"></span>
        {{ $t('casesDetail.publicCases.casesDiagnosis.zdyq') }}
      </h5>
      <div class="s_l_box">
        <template v-for="(item, index) in casesData">
          <div
              v-if="!item.isHide"
              :key="`${item.title}_${index}`"
              class="s_l_con">
            <div class="s_l_con_left">
              {{ item.fIndex ? item.fIndex + '.' : '' }}{{ item.title ? item.title + ':' : '' }}
            </div>
            <div v-if="item.key !== 'teeth'" class="s_l_con_right">
              <div
                  v-for="(pItem, pIndex) in item.pList"
                  :key="`${pItem.filed}_${pIndex}`"
                  class="p">
                <div v-if="pItem.type !== 'note' && details[pItem.filed]">
                  <div v-if="pItem.filed === 'middleLine'" class="r_strong">
                    <div class="fzBold mb10">{{ JSON.parse(details[pItem.filed]).value }}</div>
                    <div v-if="JSON.parse(details[pItem.filed]).maxillary" class="fzBold mb10">
                      {{ JSON.parse(details[pItem.filed]).maxillary.text }}
                      {{ JSON.parse(details[pItem.filed]).maxillary.value }}
                      {{ JSON.parse(details[pItem.filed]).maxillary.input }}
                    </div>
                    <div v-if="JSON.parse(details[pItem.filed]).submaxill" class="fzBold">
                      {{ JSON.parse(details[pItem.filed]).submaxill.text }}
                      {{ JSON.parse(details[pItem.filed]).submaxill.value }}
                      {{ JSON.parse(details[pItem.filed]).submaxill.input }}
                    </div>
                  </div>
                  <div v-if="pItem.filed === 'backToothInfo'" class="r_strong">
                    <div v-if="JSON.parse(details[pItem.filed]).value" class="fzBold mb10">
                      {{ JSON.parse(details[pItem.filed]).value }}
                    </div>
                    <div v-if="JSON.parse(details[pItem.filed]).children" class="fzBold">
                      {{ join(map(JSON.parse(details[pItem.filed]).children, items => items.value)) }}
                    </div>
                  </div>
                  <div v-if="pItem.filed === 'overbiteInfo'" class="r_strong">
                    <div v-if="JSON.parse(details[pItem.filed]).value" class="fzBold mb10">
                      {{ JSON.parse(details[pItem.filed]).value }}
                    </div>
                    <div v-if="JSON.parse(details[pItem.filed]).children" class="fzBold">
                      {{ join(map(JSON.parse(details[pItem.filed]).children, items => items.value)) }}
                    </div>
                    <div v-if="JSON.parse(details[pItem.filed]).children" class="fzBold">
                      {{
                        join(map(filter(flattenDeep(map(JSON.parse(details[pItem.filed]).children, items => items.children))), it => it.value))
                      }}
                    </div>
                  </div>
                  <div v-if="pItem.filed === 'frontToothInfo'" class="r_strong">
                    <div v-if="JSON.parse(details[pItem.filed]).multiple" class="fzBold mb10">
                      {{ join(map(JSON.parse(details[pItem.filed]).multiple, items => items.value)) }}
                    </div>
                    <div v-if="JSON.parse(details[pItem.filed]).children" class="fzBold">
                      {{ join(map(JSON.parse(details[pItem.filed]).children, items => items.value)) }}
                    </div>
                  </div>

                  <span class="r_strong">
                  {{
                      !['clinicInfo', 'toothProblem', 'jawProblem', 'toothProblemTwo'].includes(pItem.filed) ?
                          diaKeyObj[details[pItem.filed]]
                          : details[pItem.filed].split(',').map(dItem => diaKeyObj[dItem]).join(',')
                    }}

                </span>
                </div>
                <div
                    v-if="pItem.type === 'note' && details[pItem.filed]"
                    :style="pItem.filed === 'leftSagittalRelationNote' || pItem.filed === 'rightSagittalRelationNote' ? {height: '1rem'} : {}"
                    class="note_box"
                >
                  <div :style="index === casesData.length - 1 ? {top: '0.3rem'} : {}" class="s_l_con_note">
                    {{ pItem.tit }}<span class="r_strong">{{
                      pItem.filed === 'leftSagittalRelationNote' || pItem.filed === 'rightSagittalRelationNote'
                          ? details[pItem.filed].split('-').map(dItem => dItem.split(',').filter(fItem => (fItem !== 'far_grind' && fItem !== 'bit_jump')).map(sItem => diaKeyObj[sItem]).join(',')).join('-')
                          : diaKeyObj[details[pItem.filed]]
                              ? diaKeyObj[details[pItem.filed]]
                              : ((pItem.filed === 'upjawMiddleLineNote' || pItem.filed === 'downjawMiddleLineNote') && details[pItem.filed])
                                  ? `${diaKeyObj[details[pItem.filed].split('-')[0]]}-${details[pItem.filed].split('-')[1]}mm`
                                  : details[pItem.filed].indexOf('-') > 0
                                      ? details[pItem.filed].split('-').map(dItem => diaKeyObj[dItem]).join('-')
                                      : details[pItem.filed].indexOf('$') > 0
                                          ? details[pItem.filed].split('>').map(tItem => `${tItem.split('$')[0]}-${diaKeyObj[tItem.split('$')[1]]}`).join(',')
                                          : details[pItem.filed]
                    }}</span>
                    <div class="r_strong">
                      {{
                        pItem.filed === 'leftSagittalRelationNote'
                            ? details['leftSagittalRelationFar']
                                ? details['leftSagittalRelationFar'].split('-').map(lItem => diaKeyObj[lItem]).join('-')
                                : '' : ''
                      }}
                    </div>
                    <div class="r_strong">
                      {{
                        pItem.filed === 'leftSagittalRelationNote'
                            ? details['leftSagittalRelationBitJump']
                                ? details['leftSagittalRelationBitJump'].split('-').map(lItem => diaKeyObj[lItem]).join('-')
                                : '' : ''
                      }}
                    </div>
                    <div class="r_strong">
                      {{
                        pItem.filed === 'rightSagittalRelationNote'
                            ? details['rightSagittalRelationFar']
                                ? details['rightSagittalRelationFar'].split('-').map(lItem => diaKeyObj[lItem]).join('-')
                                : '' : ''
                      }}
                    </div>
                    <div class="r_strong">
                      {{
                        pItem.filed === 'rightSagittalRelationNote'
                            ? details['rightSagittalRelationBitJump']
                                ? details['rightSagittalRelationBitJump'].split('-').map(lItem => diaKeyObj[lItem]).join('-')
                                : '' : ''
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="item.key === 'teeth'" class="s_l_con_teeth">
              <teeth-com :babyTeethKeyObj="babyTeethKeyObj"
                         :teethKeyObj="teethKeyObj[item.filed]"/>
            </div>
          </div>
        </template>
      </div>
    </div>
    <full-loading v-show="isLoading"/>
  </li>
</template>

<script>
import FullLoading from 'components/full-loading/full-loading';
import {getDictAllList} from 'common/api/public';
import TeethCom from 'components/teeth-com/teeth';
import {join, map, flattenDeep, filter, get} from 'lodash';
import ChildrenTeeth from '../../../components/treatment-table/children-teeth/index';
import {mapGetters} from 'vuex';

export default {
  methods: {
    join,
    map,
    flattenDeep,
    filter,
    get
  },
  data() {
    return {
      isLoading: true,
      diaKeyObj: {},

      commonTeeth: []
    }
  },
  computed: {
    ...mapGetters({
      'commonCaseCureDetail': 'getCommonCaseCureDetail',//治疗表详情
    }),
    queryPort() {
      return this.$route.query.port;
    }
  },
  props: {
    casesData: {
      type: Array,
      default: () => ([])
    },
    details: {
      type: Object,
      default: () => ({})
    },
    teethKeyObj: {
      type: Object,
      default: () => ({})
    },
    babyTeethKeyObj: {
      type: Object,
      default: () => ({})
    },
  },
  created() {
    getDictAllList([
      'tooth_problem',
      'jaw_problem',
      'clinic_info',
      'to_correct_tooth_jaw',
      'to_correct_tooth_upjaw',
      'tooth_problem_two',
      'jaw_problem_two',
      'left_sagittal_relation',
      'right_sagittal_relation',
      'cover_info',
      'overbite_info',
      'front_tooth_info',
      'back_tooth_info',
      'middle_line',
      'improve_middle_move',
      'updown_jaw',
      'updown_jaw_gap_correct',
      'choose_option',
      'correct_deep_cover',
      'correct_open',
      'downjaw_far',
      'upjaw_far',
      'grow_nail_flag',
      'underjaw_abnormal_flag',
      'underjaw_abnormal_flag',
      'back_tooth_info_correct',
      'correct_front_reverse',
      'correct_front_opposite',
      'improve_tip',
      'to_correct_tooth_upjaw',
      'tooth_extraction_options',
      'tooth_extraction',
      'precision_cut',
      'angle_classification',
      'sagittal_maxillomandibular_classification',
      'to_correct_tooth_downjaw'
    ]).then(data => {
      this.isLoading = false;
      let diaKeyObj = {};
      data.forEach(item => {
        diaKeyObj[item.key] = item.value;
      });
      this.diaKeyObj = diaKeyObj;
    }).catch(() => {
      this.isLoading = false;
    });
  },
  components: {
    TeethCom,
    FullLoading,
    ChildrenTeeth
  }
}
</script>

<style lang="scss" scoped>
.preview_single {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.51rem;
}

.preview_s_left {
  .s_l_h5 {
    display: flex;
    align-items: center;
    margin-bottom: 0.29rem;

    .s_l_icon {
      //margin-right: 0.1rem;
      display: inline-block;
      width: 0.15rem;
      height: 0.16rem;
      background-size: 100% 100%;
    }

    .yellow_icon {
      background-image: url("/static/images/createCases/preview_diag.png");
    }

    .blue_icon {
      background-image: url("../../../common/imgs/user_data/blue_diag.png");
    }

    .purple_icon {
      background-image: url("../../../common/imgs/user_data/purple_diag.png");
    }

  }

  .s_l_box {
    color: #666;
    padding-left: 0.24rem;

    .s_l_box_p {
      display: flex;
      align-items: center;
      margin-bottom: 0.21rem;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        margin-right: 0.2rem;
      }
    }

    .s_l_con {
      display: flex;
      margin-bottom: 0.21rem;
      position: relative;

      .s_l_con_left {
        margin-right: 0.1rem;
        white-space: nowrap;
        margin-top: 0.05rem;
      }

      .s_l_con_right {
        flex: 1;

        .p {
          margin-bottom: 0.11rem;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .note_box {
        }

        .s_l_con_note {
          width: 100%;
          margin-left: -1%;
          padding: 0.2rem 0.1rem;
          background-color: #F3F3F3;
          line-height: 0.2rem;
        }

        .r_strong {
          font-weight: 600;
          margin-left: 0.1rem;
          color: #111;
          line-height: 0.25rem;
        }
      }

      .s_l_con_teeth {
        padding-top: 0.4rem;
        padding-bottom: 0.2rem;
      }
    }
  }

  .pre_list {
    margin-bottom: 0.1rem;
    padding-left: 0.24rem;

    .s_l_txt {
      color: #666;
      margin-bottom: 0.12rem;
    }

    li {
      width: 3.14rem;
      height: 2rem;
      border-radius: 0.06rem;
      background-size: 100% 100%;
      margin-bottom: 0.2rem;

      &:last-child {
        margin-right: 0;
      }
    }

    .pic_list_space {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }

    .pic_list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      li {
        margin-right: 0.3rem;
      }
    }
  }
}

.preview_s_right {
  width: 6.3rem;
  height: 0.85rem;
  display: flex;
  align-items: center;
  padding-left: 0.28rem;
  box-sizing: border-box;
  background-color: #F3F3F3;
  border-radius: 0.06rem;

  .preview_s_r_strong {
    font-size: 0.2rem;
    margin-left: 0.1rem;
    margin-right: 0.09rem;
  }

  .preview_s_r_icon {
    display: inline-block;
    width: 0.18rem;
    height: 0.18rem;
    background-color: yellow;
    background-size: 100% 100%;
    cursor: pointer;
  }
}
</style>

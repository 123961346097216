<template>
  <div class="wrap">
    <div class="content">
      <div class="teeth" @click="teethClick(index,item,'one')" v-for='(item,index) in teethOne' :key="index">
        <span>{{item.number}}</span>
        <el-popover
          placement="bottom"
          @after-leave="popoverHide(index, 'one', item)"
          :disabled="readOnly"
          ref="onePopover"
          trigger="click">
          <div class="flex-y-c posr">
            <el-input :value="item.width"
                      :placeholder="placeholderText"
                      @change="disposeNumber({key: item, value: arguments[0]})"
                      @input="disposeNumber({key: item, value: arguments[0]})"
                      style="width: 1.8rem" v-model="item.width"></el-input>
            <span class="input_mm">mm</span>
          </div>
          <div v-if="index !== teethOne.length - 1"
               @click="resetNumber(item,'teethOne',index)"
               :class="{
                active_img: item.width,
                [portImg]: item.width,
               }"
               class="curp hvr-bd" slot="reference"></div>
        </el-popover>
      </div>
    </div>
    <div class="content">
      <div class="teeth" @click="teethClick(index,item,'tow')" v-for='(item,index) in teethTow' :key="index">
        <span>{{item.number}}</span>
        <el-popover
          placement="bottom"
          :disabled="readOnly"
          @after-leave="popoverHide(index, 'tow', item)"
          ref="twoPopover"
          trigger="click">
          <div class="flex-y-c posr">
            <el-input
              :value="item.width"
              :placeholder="placeholderText"
              @change="disposeNumber({key: item, value: arguments[0]})"
              @input="disposeNumber({key: item, value: arguments[0]})"
              style="width: 1.8rem" v-model="item.width"></el-input>
            <span class="input_mm">mm</span>
          </div>
          <div v-if="index !== teethTow.length - 1" :class="{
            active_img: item.width,
            [portImg]: item.width,
          }"
               @click="resetNumber(item,'teethTow',index)"
               class="curp hvr-bd" slot="reference"></div>
        </el-popover>
      </div>
    </div>
    <div class="detail" v-if="find(totalTeeth, item => item.width)">
      <template v-for="(item, index) in totalTeeth">
        <p class="fz14rem mb1rem" :key="index" v-if="item.width">
          {{item.number}},
          {{item.nextNumber}}
          {{jointText}}
          <span>{{item.width}}  mm</span>
        </p>
      </template>
    </div>
  </div>
</template>

<script>
  import {
    extend,
    map,
    pick,
    filter,
    each,
    pickBy,
    uniq,
    isNumber,
    find
  } from "lodash";
  import {toNumber} from "../../../common/js/Fn";

  export default {
    data() {
      return {
        teethOne:[
          {
            number: 55,
            nextNumber: 54,
            width: undefined
          },
          {
            number: 54,
            nextNumber: 53,
            width: undefined
          },
          {
            number: 53,
            nextNumber: 52,
            width: undefined
          },
          {
            number: 52,
            nextNumber: 51,
            width: undefined
          },
          {
            number: 51,
            nextNumber: 61,
            width: undefined
          },
          {
            number: 61,
            nextNumber: 62,
            width: undefined
          },
          {
            number: 62,
            nextNumber: 63,
            width: undefined
          },
          {
            number: 63,
            nextNumber: 64,
            width: undefined
          },
          {
            number: 64,
            nextNumber: 65,
            width: undefined
          },
          {
            number: 65,
            nextNumber: 66,
            width: undefined
          },
        ],
        teethTow: [
          {
            number: 85,
            nextNumber: 84,
            width: undefined
          },
          {
            number: 84,
            nextNumber: 83,
            width: undefined
          },
          {
            number: 83,
            nextNumber: 82,
            width: undefined
          },
          {
            number: 82,
            nextNumber: 81,
            width: undefined
          },
          {
            number: 81,
            nextNumber: 71,
            width: undefined
          },
          {
            number: 71,
            nextNumber: 72,
            width: undefined
          },
          {
            number: 72,
            nextNumber: 73,
            width: undefined
          },
          {
            number: 73,
            nextNumber: 74,
            width: undefined
          },
          {
            number: 74,
            nextNumber: 75,
            width: undefined
          },
          {
            number: 75,
            nextNumber: 76,
            width: undefined
          },
        ],
        teethList: [],
        setList: false
      };
    },
    props:{
      crowdTeethList:{
        type: Array
      },
      readOnly: {
        type: Boolean
      },
      productType:{
        type: String,
      },
      commonTeeth:{
        type: Array
      }
    },
    watch:{
      crowdTeethList:{
        handler(val){
          if(val.length){
            if(this.setList){
              this.teethList = val;
              this.setList = false;
            }
            this.teethOne = map(this.teethOne, item => {
              let obj = item;
              each(val, it=>{
                if(item.number === it.number){
                  return obj = extend({},item,it)
                }
              });
              return obj
            });
            this.teethTow = map(this.teethTow, item => {
              let obj = item;
              each(val, it=>{
                if(item.number === it.number){
                  return obj = extend({},item,it)
                }
              });
              return obj
            });
          }
        },
        immediate:true,
      },
    },
    computed: {
      totalTeeth(){
        return [...this.teethOne, ...this.teethTow]
      },
      portImg(){
        return {
          'admin': 'blue_active_img',
          'clinic': 'blue_active_img',
          'irdt': 'purple_active_img',
        }[this.$route.query.port] || 'yellow_active_img'
      },
      placeholderText(){
        return ['A', 'E', 'I'].includes(this.productType) ? this.$t('cases.createDiagnosis.jxl') : this.$t('cases.createDiagnosis.qyl')
      },
      jointText(){
        return ['A', 'E', 'I'].includes(this.productType) ? this.$t('cases.createDiagnosis.yljgw') : this.$t('cases.createDiagnosis.toglaze')
      }
    },
    methods: {
      find,
      popoverHide(index, type, item){
        if(item && !item.width){
          this.teethList = filter(this.teethList, it => it.number !== item.number);
          this.$refs[`${type}Popover`][index].doClose();
        }
      },
      teethClick(index, item, type){
        // doShow()   doClose()
        if(map(this.teethList, it => it.number).includes(item.number)){
          this.$refs[`${type}Popover`][index].doClose();
          this.teethList = filter(this.teethList, it => it.number !== item.number);
        }else{
          this.teethList.push(item);
          this.$refs[`${type}Popover`][index].doShow();
        }
      },
      disposeNumber({key,value}){
        if(value === '') return key.input = '';
        key.width = isNumber(toNumber(value)) && toNumber(value) <= 10 ? toNumber(value) : ''
      },
      resetNumber(item,list,index){
        if(this.readOnly) return;
        if(item.width){
          this[list][index].width = undefined;
        }
      },
      clearTeeth(){
        each(this.teethOne, item=>{
          item.width = undefined;
        });
        each(this.teethTow, item=>{
          item.width = undefined;
        });
      }
    },
    created() {
      this.setList = true;
    }
  }
</script>

<style lang="scss" scoped>
  .input_mm{
    position: absolute;
    right: 0.1rem;
    top: 0.1rem;
  }
  .wrap{
    width: 100%;
    .content{
      display: flex;
      justify-content: center;
      margin-bottom: 0.2rem;
      .teeth{
        display: flex;
        align-items: center;
        margin-left: 0.1rem;
        .interval_input{
          display: flex;
        }
        span{
          font-size: 0.16rem;
        }
        div{
          margin-left: 0.1rem;
          width: 0.25rem;
          height: 0.25rem;
          border: 0.01rem solid #898989;
          text-align: center;
          line-height: 0.4rem;
          border-radius: 0.05rem;
        }
      }
    }
    .detail{
      padding: 0.2rem;
      width: 65%;
      background-color: #efefef;
      margin: 0 auto;
    }
  }
  .input_width{
    margin-top: 0.2rem;
    width: 2.3rem;
  }
  .active_img{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .yellow_active_img{
    background-image: url('./img/adjoin.png');
  }
  .blue_active_img{
    background-image: url('./img/blue_adjoin.png');
  }
  .purple_active_img{
    background-image: url('./img/purple_adjoin.png');
  }
</style>
<template>
  <ul class="teeth_ul" @click.stop="selectedTeeth">
    <li class="teeth_single">
      <div class="single_01">
          <p
            v-for="item in [51, 52, 53, 54, 55]"
            :key="`teeth_${item}`"
            :class="(selectedTeethTypeObj[item] && selectedTeethTypeObj[item].type === '7') || selectedBodyObj[item]
            ? 'single_ac_p' : ''"
          >
            <span
              class="single_mdl"
              data-isBaby="1"
              :data-key="item"
              data-type="7"
            ></span>
            <span
              class="single_02_txt"
              :class="selectedBodyKeyList.includes(item) && selectedTeethTypeObj[item] && selectedTeethTypeObj[item].type !== '5'
              ? 'txt_active' : ''"
            >{{item}}</span>
            <span
              class="tag_sp"
              :class="selectedTeethTypeObj[item] ? selectedTeethTypeObj[item].className : ''"
              v-show="selectedBodyKeyList.includes(item) && selectedTeethTypeObj[item] && selectedTeethTypeObj[item].type !== '5'"
            ></span>
          </p>
      </div>
      <div class="single_02">
          <p
            v-for="item in [11, 12, 13, 14, 15, 16, 17, 18]"
            :key="`teeth_${item}`"
            :data-key="item"
            :data-type="selectedTeethTypeObj[item] && selectedTeethTypeObj[item].type"
          >
            <span
              :data-key="item"
              :data-type="selectedTeethTypeObj[item] && selectedTeethTypeObj[item].type"
              class="single_02_txt"
              :class="selectedKeyList.includes(item) && selectedTeethTypeObj[item] && selectedTeethTypeObj[item].type !== '5' ? 'txt_active' : ''"
            >{{item}}</span>
            <span
              class="tag_sp"
              :class="selectedTeethTypeObj[item] ? selectedTeethTypeObj[item].className : ''"
              :data-key="item"
              :data-type="selectedTeethTypeObj[item] && selectedTeethTypeObj[item].type"
              v-show="selectedKeyList.includes(item) && selectedTeethTypeObj[item] && selectedTeethTypeObj[item].type !== '5'"
            ></span>
          </p>
      </div>
    </li>
    <li class="teeth_single">
      <div class="single_01">
        <p
          v-for="item in [61, 62, 63, 64, 65]"
          :key="`teeth_${item}`"
          :class="(selectedTeethTypeObj[item] && selectedTeethTypeObj[item].type === '7') || selectedBodyObj[item]
            ? 'single_ac_p' : ''">
            <span
              class="single_mdl"
              data-isBaby="1"
              :data-key="item"
              data-type="7"
            ></span>
          <span
            class="single_02_txt"
            :class="selectedBodyKeyList.includes(item) && selectedTeethTypeObj[item] && selectedTeethTypeObj[item].type !== '5'
              ? 'txt_active' : ''"
          >{{item}}</span>
          <span
            class="tag_sp"
            :class="selectedTeethTypeObj[item] ? selectedTeethTypeObj[item].className : ''"
            v-show="selectedBodyKeyList.includes(item) && selectedTeethTypeObj[item] && selectedTeethTypeObj[item].type !== '5'"
          ></span>
        </p>
      </div>
      <div class="single_02">
          <p
            v-for="item in [21, 22, 23, 24, 25, 26, 27, 28]"
            :key="`teeth_${item}`"
            :data-key="item"
            :data-type="selectedTeethTypeObj[item] && selectedTeethTypeObj[item].type">
            <span
              :data-key="item"
              :data-type="selectedTeethTypeObj[item] && selectedTeethTypeObj[item].type"
              class="single_02_txt"
              :class="selectedKeyList.includes(item) && selectedTeethTypeObj[item] && selectedTeethTypeObj[item].type !== '5' ? 'txt_active' : ''"
            >{{item}}</span>
            <span
              class="tag_sp"
              :class="selectedTeethTypeObj[item] ? selectedTeethTypeObj[item].className : ''"
              :data-key="item"
              :data-type="selectedTeethTypeObj[item] && selectedTeethTypeObj[item].type"
              v-show="selectedKeyList.includes(item) && selectedTeethTypeObj[item] && selectedTeethTypeObj[item].type !== '5'"
            ></span>
          </p>
      </div>
    </li>
    <li class="teeth_single">
      <div class="single_02">
          <p
            v-for="item in [41, 42, 43, 44, 45, 46, 47, 48]"
            :key="`teeth_${item}`"
            :data-key="item"
            :data-type="selectedTeethTypeObj[item] && selectedTeethTypeObj[item].type"
          >
            <span
              :data-key="item"
              :data-type="selectedTeethTypeObj[item] && selectedTeethTypeObj[item].type"
              class="single_02_txt"
              :class="selectedKeyList.includes(item) && selectedTeethTypeObj[item] && selectedTeethTypeObj[item].type !== '5' ? 'txt_active' : ''"
            >{{item}}</span>
            <span
              class="tag_sp"
              :data-key="item"
              :class="selectedTeethTypeObj[item] ? selectedTeethTypeObj[item].className : ''"
              :data-type="selectedTeethTypeObj[item] && selectedTeethTypeObj[item].type"
              v-show="selectedKeyList.includes(item) && selectedTeethTypeObj[item] && selectedTeethTypeObj[item].type !== '5'"
            ></span>
          </p>
      </div>
      <div class="single_01">
        <p
          v-for="item in [81, 82, 83, 84, 85]"
          :key="`teeth_${item}`"
          :class="(selectedTeethTypeObj[item] && selectedTeethTypeObj[item].type === '7') || selectedBodyObj[item]
            ? 'single_ac_p' : ''"
          data-type="7"
        >
            <span
              class="single_mdl"
              data-isBaby="1"
              :data-key="item"
              data-type="7"
            ></span>
          <span
            class="single_02_txt"
            :class="selectedBodyKeyList.includes(item) && selectedTeethTypeObj[item] && selectedTeethTypeObj[item].type !== '5'
              ? 'txt_active' : ''"
          >{{item}}</span>
          <span
            class="tag_sp"
            :class="selectedTeethTypeObj[item] ? selectedTeethTypeObj[item].className : ''"
            v-show="selectedBodyKeyList.includes(item) && selectedTeethTypeObj[item] && selectedTeethTypeObj[item].type !== '5'"
          ></span>
        </p>
      </div>
    </li>
    <li class="teeth_single">
      <div class="single_02">
          <p
            v-for="item in [31, 32, 33, 34, 35, 36, 37, 38]"
            :key="`teeth_${item}`"
            :data-key="item"
            :data-type="selectedTeethTypeObj[item] && selectedTeethTypeObj[item].type"
          >
            <span
              :data-key="item"
              :data-type="selectedTeethTypeObj[item] && selectedTeethTypeObj[item].type"
              class="single_02_txt"
              :class="selectedKeyList.includes(item) && selectedTeethTypeObj[item] && selectedTeethTypeObj[item].type !== '5' ? 'txt_active' : ''"
            >{{item}}</span>
            <span
              class="tag_sp"
              :class="selectedTeethTypeObj[item] ? selectedTeethTypeObj[item].className : ''"
              :data-key="item"
              :data-type="selectedTeethTypeObj[item] && selectedTeethTypeObj[item].type"
              v-show="selectedKeyList.includes(item) && selectedTeethTypeObj[item] && selectedTeethTypeObj[item].type !== '5'"
            ></span>
          </p>
      </div>
      <div class="single_01">
        <p
          v-for="item in [71, 72, 73, 74, 75]"
          :key="`teeth_${item}`"
          :class="(selectedTeethTypeObj[item] && selectedTeethTypeObj[item].type === '7') || selectedBodyObj[item]
            ? 'single_ac_p' : ''"
          data-type="7"
        >
            <span
              class="single_mdl"
              data-isBaby="1"
              :data-key="item"
              data-type="7"
            ></span>
          <span
            class="single_02_txt"
            :class="selectedBodyKeyList.includes(item) && selectedTeethTypeObj[item] && selectedTeethTypeObj[item].type !== '5'
              ? 'txt_active' : ''"
          >{{item}}</span>
          <span
            class="tag_sp"
            :class="selectedTeethTypeObj[item] ? selectedTeethTypeObj[item].className : ''"
            v-show="selectedBodyKeyList.includes(item) && selectedTeethTypeObj[item] && selectedTeethTypeObj[item].type !== '5'"
          ></span>
        </p>
      </div>
    </li>
  </ul>
</template>

<script>
  import {notifyMsg} from 'common/js/util';
  import {filter} from 'lodash'
  export default {
    data() {
      return {
        teethTypeObj: {
          '1': 'teeth_missing', // 缺失
          '2': 'teeth_no_move', // 不可移动
          '3': 'teeth_no_design', // 不可设计
          '4': 'teeth_extraction', // 拔牙
          '6': 't__s_tag_fj'
        },
        selectedBodyKeyList: [],
        selectedKeyList: [],
        selectedTeethTypeObj: {},
        selectedBodyObj: {},
        interTeeth: null
      }
    },
    props: {
      teethType: {
        type: String,
        default: ''
      },
      teethKeyObj: {
        type: Object,
        default: () => ({})
      },
      babyTeethKeyObj: {
        type: Object,
        default: () => ({})
      },
    },
    methods: {
      selectedTeeth(ev) {
        if(!this.teethType) {
          return false;
        }
        const target = ev.target;
        if(target.tagName.toLocaleLowerCase() === 'p' || target.tagName.toLocaleLowerCase() === 'span') {
          const key = +target.getAttribute('data-key');
          const type = target.getAttribute('data-type');
          const isBaby = target.getAttribute('data-isBaby');
          if(key && (!isBaby || this.teethType !== '1')) {
            fn.call(this);
          }else if(this.teethType === '1' && isBaby) {
            const index = this.selectedBodyKeyList.indexOf(key);
            if(index > -1) {
              this.selectedBodyKeyList.splice(index, 1);
              delete this.selectedBodyObj[key];
              delete this.selectedTeethTypeObj[key];
              this.$emit('selectedTeeth', {
                type: this.teethType,
                obj: this.selectedTeethTypeObj
              });
            }else {
              this.selectedBodyKeyList.push(key);
              this.selectedBodyObj[key] = {
                type: '7',
                number: key
              };
            }
            if(this.interTeeth) {
              clearTimeout(this.interTeeth);
            }
            this.interTeeth = setTimeout(() => {
              this.$emit('selectedBabyTeeth', this.selectedBodyObj);
            }, 1000);
          }
          function fn() {
            if(this.teethType !== '1' && !this.selectedBodyObj[key] && isBaby) {
              return false;
            }
            if(type && (type !== this.teethType && this.teethType !== '1') && !isBaby) {
              return false;
            }
            const index = this.selectedKeyList.indexOf(key);
            if(index > -1) {
              this.selectedKeyList.splice(index, 1);
              if(type === '7') {
                this.selectedTeethTypeObj[key] = {
                  type: this.teethType,
                  className: this.teethTypeObj[this.teethType],
                  number: key
                }
              }else {
                delete this.selectedTeethTypeObj[key];
              }
            }else {
              this.selectedKeyList.push(key);
              this.selectedTeethTypeObj[key] = {
                type: this.teethType,
                className: this.teethTypeObj[this.teethType],
                number: key
              };
            }
            if(this.interTeeth) {
              clearTimeout(this.interTeeth);
            }
            this.interTeeth = setTimeout(() => {
              this.$emit('selectedTeeth', {
                type: this.teethType,
                obj: this.selectedTeethTypeObj
              });
            }, 1000);
          }
        }
      }
    },
    created(){
      const ll = Object.keys(this.babyTeethKeyObj).map(item => {
        return +item;
      });
      this.selectedBodyKeyList = ll;
      this.selectedBodyObj = {...this.babyTeethKeyObj};

      this.selectedKeyList = Object.keys(this.teethKeyObj).map(item => {
        return +item;
      });
      this.selectedTeethTypeObj = {
        ...this.teethKeyObj
      };
    },
    watch: {
      teethKeyObj: { // 监听牙齿变化信息
        immediate: true,
        handler(val) {
          this.selectedKeyList = Object.keys(val).map(item => {
            return +item;
          });
          this.selectedTeethTypeObj = {
            ...val
          };
        },
        deep: true,
      },
      babyTeethKeyObj: { // 监听滞留的乳牙
        handler(val) {
          const ll = Object.keys(val).map(item => {
            return +item;
          });
          this.selectedBodyKeyList = ll;
          this.selectedBodyObj = {...val};
        },
        deep: true
      }
    }
  }
</script>

<style scoped lang="scss">
  .teeth_ul{
    width: 7.8rem;
    display: flex;
    flex-wrap: wrap;
    font-size: 0.18rem;
    color: #999999;
    margin: 0 auto;
    .teeth_single{
      width: 50%;
      box-sizing: border-box;
      &:first-child{
        border-right: 0.01rem #C1C1C1 dashed;
        border-bottom: 0.01rem #C1C1C1 dashed;
        padding-bottom: 0.11rem;
        padding-right: 0.09rem;
        .single_01{
          margin-bottom: 0.26rem;
          flex-direction: row-reverse;
        }
        .single_02{
          flex-direction: row-reverse;
        }
        p{
          margin-left: 0.18rem;
        }
      }
      &:last-child{
        border-left: 0.01rem #C1C1C1 dashed;
        border-top: 0.01rem #C1C1C1 dashed;
        padding-top: 0.14rem;
        padding-left: 0.09rem;
        transform: translate(-0.01rem,-0.01rem);
        .single_01{
          margin-top: 0.26rem;
        }
        p{
          margin-right: 0.18rem;
        }
      }
      &:nth-of-type(2) {
        padding-bottom: 0.11rem;
        padding-left: 0.09rem;
        .single_01{
          margin-bottom: 0.26rem;
        }
        p{
          margin-right: 0.18rem;
        }
      }
      &:nth-of-type(3) {
        padding-right: 0.09rem;
        padding-top: 0.14rem;
        .single_01{
          margin-top: 0.26rem;
          flex-direction: row-reverse;
        }
        .single_02{
          flex-direction: row-reverse;
        }
        p{
          margin-left: 0.18rem;
        }
      }
      .single_01{
        display: flex;
        align-items: center;
        p{
          border: 0.01rem solid #C8C8C8;
          box-sizing: border-box;
          overflow: hidden;
          cursor: pointer;
        }
        .single_ac_p {
          border: 0.01rem solid $main_theme_color;
          color: $main_theme_color;
        }
      }
      .single_02{
        display: flex;
        align-items: center;
        p{
          border: 0.01rem solid $main_theme_color;
          color: $main_theme_color;
          cursor: pointer;
          box-sizing: border-box;
          overflow: hidden;
        }
      }
      p{
        min-width: 0.32rem;
        min-height: 0.32rem;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.06rem;
        .single_02_txt{
          position: absolute;
          right: 50%;
          top: 50%;
          transform: translate(50%, -50%);
          font-size: 0.2rem;
        }
        .txt_active{
          color: $main_theme_color;
          top: 35%;
          right: 35%;
          font-size: 0.2rem;
          z-index: 2;
        }
        .tag_sp{
          position: absolute;
          left: -1px;
          bottom: -1px;
          right: -1px;
          top: -1px;
          display: inline-block;
          background-size: (100% 100%);
        }
      }
      .single_mdl{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 9;
        cursor: pointer;
      }
    }
    .teeth_missing{
      background-image: url("/static/images/createCases/teeth_defect.png");
    }
    .teeth_no_move{
      background-image: url("/static/images/createCases/teeth_fixed.png");
    }
    .teeth_no_design{
      background-image: url("/static/images/createCases/teeth_notdesigned.png");
    }
    .teeth_extraction{
      background-image: url("/static/images/createCases/teeth_extraction.png");
    }
    .t__s_tag_fj {
      background-image: url("/static/images/teeth_fj_tag.png");
      right: 45% !important;
      top: 45% !important;
    }
  }
</style>
